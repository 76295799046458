<template>
  <div>
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Ready-to-go journeys</h1>
      <p>Ready-to-go include a virtual course map, milestones, custom emails and are a great way to quickly launch engaging challenges.</p>
      <p>Select one of the templates below to see details of each of the ready-to-go journey.</p>
      <p>
        <v-btn color="primary" :to="{name: 'eventmanagerStart', query: {tags: 'MAP' }}">Start a Journey</v-btn>
      </p>
    </div>
    
    <div class="white--text pb-6 pb-md-12">
      <div class="containerZ">
        <v-row >
          <v-col v-for="(item, idx) in templates" :key="idx" cols="4">
            <MapTemplateCard :item="item" :to="{name: 'challengeJourneyView', params: {id : item.id }}" />
          </v-col>
        </v-row>
      </div>
    </div>

    <div>
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h3>Create your Custom journey</h3>
            <p>Next to these ready-to-go journeys, you can easily create your own custom journey based on your own custom map and theme. Reach out to us to get started.</p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="green white--text py-6 py-md-12 my-4">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Go Live Today!</h2>
            <h1 class="subtitle">Create your personalized fitness challenge within minutes!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'startTrial'}">Start Free Trial</v-btn>
              <v-btn v-if="false" class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import assetsService from "@/services/assetsService";
import navData from '@/data/nav.json'
import EventTemplateCard from '@/components/templates/EventTemplateCard.vue'
import MapTemplateCard from '@/components/templates/MapTemplateCard.vue'

export default {
  name: "Contact",
  components: {
    MapTemplateCard,
    EventTemplateCard,
  },
  props: {
  },
  data: function() {
    return {
      navData: navData,
      templates: null,
    };
  },
  created() {
  },
  async mounted() {
    this.templates = (await assetsService.getMapTemplates()).data.data;
  },
  methods: {
   
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>